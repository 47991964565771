import React, { useEffect, useMemo, useState } from 'react';
import {
    BuyBoxEditableCurrencyItem,
    BuyBoxLineItem,
    BuyBoxEditablePercentItem,
    BuyBoxEditableItem,
} from './buyBoxItems';
import {
    calculateAdditionalCosts,
    calculateXIRRInfoFromProperty,
} from '../../utilities/buyBoxHelpers';
import { PropertyScoutFieldNames } from '../scout/scout';
import './scoutBuyBox.css';

const RECALCULATE_DEBOUNCE_TIME = 1000;

const ScoutBuyBox = ({ property, onScoutFieldsUpdate }) => {
    const { PropertyScoutFields } = property.propertyDetails;
    const {
        generic_data: { buyBox },
    } = PropertyScoutFields;

    const [inputValues, setInputValues] = useState(buyBox);
    const [computedxIRRInfo, setComputedxIRRInfo] = useState();

    const spread = useMemo(() => {
        const diff = Number(inputValues.salePrice - inputValues.acquisitionPrice);
        return diff < 0
            ? `( $${diff.toLocaleString()})`
            : `$${diff.toLocaleString()}`;
    }, [inputValues.salePrice, inputValues.acquisitionPrice]);

    const additionalCosts = useMemo(() => {
        return calculateAdditionalCosts(
            property.propertyDetails.PropertyScoutFields?.validation_fields
        );
    }, [property]);

    const updateIRR = () =>
        calculateXIRRInfoFromProperty({
            ...PropertyScoutFields,
            generic_data: {
                ...PropertyScoutFields.generic_data,
                buyBox: { ...inputValues },
            },
        });

    useEffect(() => {
        let timer;
        if (!computedxIRRInfo) {
            setComputedxIRRInfo(updateIRR());
        } else {
            timer = setTimeout(() => {
                setComputedxIRRInfo(updateIRR());
                saveInputValues();
            }, RECALCULATE_DEBOUNCE_TIME);
        }
        return () => clearTimeout(timer);
    }, [inputValues]);

    const saveInputValues = () => {
        const genericData = {
            ...property.propertyDetails.PropertyScoutFields.generic_data,
        };
        genericData.buyBox = {
            ...inputValues,
        };
        onScoutFieldsUpdate(PropertyScoutFieldNames.GenericData, genericData);
    };

    const handleChange = (propName, value) => {
        setInputValues({ ...inputValues, [propName]: value });
    };

    const handleTimelineChange = (propName, value) => {
        setInputValues({
            ...inputValues,
            timeline: { ...inputValues.timeline, [propName]: Number(value) },
        });
    };

    const renderTargetPropertyPriceDetailsSection = () => (
        <div className="buyboxSection flex-basis-100">
            <div className="bg-white py-2">
                <h3 className="fs-5">Target Property Price Details</h3>
            </div>
            <div className="border py-3 px-3 rounded buyboxSubsection">
                <BuyBoxEditableCurrencyItem
                    label="Acquisition Price"
                    name="acquisitionPrice"
                    value={inputValues.acquisitionPrice}
                    onChange={handleChange}
                />
                <BuyBoxEditableCurrencyItem
                    label="Sale Price"
                    name="salePrice"
                    value={inputValues.salePrice}
                    onChange={handleChange}
                />
                <BuyBoxLineItem label="Spread" value={spread} />
                <BuyBoxEditablePercentItem
                    label="Sell Side Total Commission"
                    value={inputValues.sellSideCommission}
                    name="sellSideCommission"
                    onChange={handleChange}
                />
                <BuyBoxLineItem
                    label="Additional Exception-Based Cost"
                    value={`$ ${additionalCosts.toLocaleString()}`}
                />
            </div>
        </div>
    );

    const renderTargetPropertyTimelineSection = () => (
        <div className="buyboxSection flex-basis-100">
            <div className="bg-white py-2">
                <h3 className="fs-5">Target Property Time Details</h3>
            </div>
            <div className="border py-3 px-3 rounded buyboxSubsection">
                <BuyBoxEditableItem
                    label="Purchase To Closing Timeline"
                    value={inputValues.timeline.purchaseToClosingTimeline}
                    onChange={(ev) =>
                        handleTimelineChange('purchaseToClosingTimeline', ev.target.value)
                    }
                />
                <BuyBoxEditableItem
                    label="Permit-to-Submission"
                    value={inputValues.timeline.permitToSubmission}
                    onChange={(ev) =>
                        handleTimelineChange('permitToSubmission', ev.target.value)
                    }
                />

                <BuyBoxEditableItem
                    label="Submission-to-Acceptance"
                    value={inputValues.timeline.submissionToAcceptance}
                    onChange={(ev) =>
                        handleTimelineChange('submissionToAcceptance', ev.target.value)
                    }
                />
                <BuyBoxEditableItem
                    label="Horizontal Days"
                    value={inputValues.timeline.horizontalDays}
                    onChange={(ev) =>
                        handleTimelineChange('horizontalDays', ev.target.value)
                    }
                />
                <BuyBoxEditableItem
                    label="Vertical Days"
                    value={inputValues.timeline.verticalDays}
                    onChange={(ev) =>
                        handleTimelineChange('verticalDays', ev.target.value)
                    }
                />
                <BuyBoxEditableItem
                    label="On Market to CoE Days"
                    value={inputValues.timeline.listToCoE}
                    onChange={(ev) => handleTimelineChange('listToCoE', ev.target.value)}
                />
            </div>
        </div>
    );

    return (
        <div className="p-4 rounded d-flex flex-column gap-3 scoutBuyboxCalculator">
            <div className="d-flex flex-column flex-lg-row gap-3">
                {renderTargetPropertyPriceDetailsSection()}
                {renderTargetPropertyTimelineSection()}
            </div>
            <div className="d-flex flex-column flex-lg-row gap-3">
                <div className="mt-2 p-2 border border rounded buyboxSection flex-basis-33">
                    <BuyBoxEditablePercentItem
                        label="Target IRR"
                        value={inputValues.targetIRR}
                        name="targetIRR"
                        onChange={handleChange}
                    />
                    <div className="d-flex mt-3 justify-content-between">
                        <div className="fw-bold">Post Interest & Comm Spread</div>
                        {computedxIRRInfo && (
                            <div className="text-end fw-bold">{computedxIRRInfo.value}</div>
                        )}
                    </div>
                </div>

                {computedxIRRInfo && (
                    <div className="mt-2 flex-basis-66">
                        <div
                            className="py-3 fw-bold fs-4 text-center rounded"
                            style={computedxIRRInfo.style}
                        >
                            <span className="fs-4 mb-2">{computedxIRRInfo.description}</span>
                            <div className="text-center fs-6">
                Difference {computedxIRRInfo.difference}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScoutBuyBox;
