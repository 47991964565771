import React, { useState } from 'react';

import ScoutDashboard from '../scoutDashboard';
import ScoutViability from '../scoutViability';
import ScoutComparables from '../scoutComparables';
import ScoutBuyBox from '../scoutBuyBox';
import ScoutTabNavigator, { ScoutTab } from './scoutTabNavigator';

function ScoutTabs({
    property,
    onScoutFieldsUpdate,
    onScoutValidationFieldsUpdate,
}) {
    const [selectedTab, setSelectedTab] = useState(ScoutTab.Dashboard);

    const selectTab = (tab) => setSelectedTab(tab);

    const getTabContentsComponent = () => {
        switch (selectedTab) {
        case ScoutTab.Dashboard:
            return (
                <ScoutDashboard
                    property={property}
                    onScoutFieldsUpdate={onScoutFieldsUpdate}
                />
            );
        case ScoutTab.Comparables:
            return (
                <ScoutComparables
                    property={property}
                    onScoutFieldsUpdate={onScoutFieldsUpdate}
                />
            );
        case ScoutTab.Viability:
            return (
                <ScoutViability
                    property={property}
                    onScoutValidationFieldsUpdate={onScoutValidationFieldsUpdate}
                />
            );
        case ScoutTab.BuyBox:
            return <ScoutBuyBox property={property} onScoutFieldsUpdate={onScoutFieldsUpdate} />;
        }
        return null;
    };

    return (
        <>
            <div className="col-12">
                <ScoutTabNavigator selected={selectedTab} onSelect={selectTab} />
            </div>
            <div className="mt-2">{getTabContentsComponent()}</div>
        </>
    );
}

export default ScoutTabs;
