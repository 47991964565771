import React, { useMemo } from 'react';
import { calculateXIRRInfoFromProperty } from '../../../utilities/buyBoxHelpers';
import {
    DashboardCardTitle,
    Dashboard_Not_Available_Label,
    convertValueForDashboardDisplay,
} from '..';
import './cards.css';

const getListingPrice = (mlsHistory) => {
    const haveInfo =
    mlsHistory && mlsHistory?.listing_status && mlsHistory?.listing_price;
    if (haveInfo) {
        const { listing_status, listing_price } = mlsHistory;
        return `${convertValueForDashboardDisplay(
            listing_price,
            Math.round,
            '$',
            ''
        )} (${listing_status})`;
    }
    return Dashboard_Not_Available_Label;
};

const AroEconomicsCard = ({ property: { propertyDetails } }) => {
    const {
        PropertyAVM,
        avg_street_price,
        max_street_price,
        MLSHistory,
        PropertyScoutFields,
    } = propertyDetails;

    const buyBoxResultInfo = useMemo(() => {
        return calculateXIRRInfoFromProperty(PropertyScoutFields);
    }, [PropertyScoutFields]);

    const avgStreetPrice = convertValueForDashboardDisplay(
        avg_street_price,
        Math.round,
        '$',
        ''
    );
    const maxStreetPrice = convertValueForDashboardDisplay(
        max_street_price,
        Math.round,
        '$',
        ''
    );
    const estimatedValue = convertValueForDashboardDisplay(
        PropertyAVM.estimated_value,
        Math.round,
        '$',
        ''
    );
    const estimatedSalePrice = convertValueForDashboardDisplay(
        PropertyAVM.future_sale_price,
        Math.round,
        '$',
        ''
    );

    const valueTDWidth = '50%';

    const renderRow = (label, value, styleClasses = null) => {
        return (
            <tr>
                <td>{label}</td>
                <td style={{ width: valueTDWidth }}>
                    <span style={{ fontWeight: 500 }} className={styleClasses}>
                        {value}
                    </span>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <DashboardCardTitle text="Aro Economics" />
            <table className="w-100">
                <tbody>
                    {renderRow('Est. Value', estimatedValue)}
                    {renderRow('Est. Sale Price', estimatedSalePrice)}
                    {renderRow('Street Avg Price', avgStreetPrice)}
                    {renderRow('Max Street Price', maxStreetPrice)}
                    {renderRow('List Price', getListingPrice(MLSHistory))}
                </tbody>
            </table>
            <table className="w-100 mt-4 mb-1">
                <tbody>
                    <tr>
                        <td className="fw-bold" style={{ fontSize: '18px' }}>
              Buy Box Result
                        </td>
                        <td style={{ width: valueTDWidth }}>
                            <span
                                className="px-2 py-1 fw-bold rounded"
                                style={buyBoxResultInfo.style}
                            >
                                {buyBoxResultInfo.difference}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AroEconomicsCard;
