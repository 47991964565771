export const colors = {
    charcoal: '#3d3935',
    skyfall: '#71b2c9',
    skyfallDark: 'hsl(195.68, 44.9%, 50%)',
    goldwood: '#B9975B',
    goldwoodLight: 'hsl(38.3, 40.17%, 85%)',
    walnut: '#8b5b29',
    white: '#fff',

    aroGreen: '#81b234',
    aroRed: '#da2836',
    aroGray: '#b8c3c7',
    aroYellow: '#fdd835', //'#f6d55c'
    aroYellowDark: 'hsl(48.9, 98.04%, 45%)',

    reChartsTickGray: '#666',
};

export const ValidationFields = [
    // correspond to Viability fields shown on Scout Dashboard/Viability Tab
    {
        key: 'StructuralConstraints',
        label: 'Structural Constraints',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'ZoningJurisdiction',
        label: 'Zoning Jurisdiction',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'FloodHazard',
        label: 'Flood Hazard',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'EarthquakeHazard',
        label: 'Earthquake and Liquefaction Hazard',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'FireHazard',
        label: 'Fire Hazard',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'CityVsCounty',
        label: 'City vs County',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'CityHistoricResource',
        label: 'City Historic Resource',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'generalNotes',
        label: 'General Notes',
        hasCostEstimate: true,
        isPassFailField: false,
    },
];

export const ValidationValues = {
    Pass: 'Pass',
    NoPass: 'No Pass',
    Concerns: 'Concerns',
    NA: 'NA',
};

export const ViabilityFields = {
    // Fields within a ValidationField
    Notes: 'notes',
    Pass: 'pass',
    CostEstimate: 'costEstimate',
};

export const PropertyInfo = {
    FutureHomeSize: 2938,
};
