import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import './scoutBuyBox.css';

export const BuyBoxLineItem = ({ label, value }) => (
    <div className="row mt-1">
        <div className="col-7">{label}</div>
        <div className="col-5 text-end pe-3">{value}</div>
    </div>
);

export const BuyBoxEditableCurrencyItem = ({
    label,
    name,
    value,
    onChange,
}) => {
    const handleInputChange = (value) => onChange(name, value);
    return (
        <div className="row mt-1">
            <div className="col-5 col-sm-7">{label}</div>
            <div className="col-7 col-sm-5 text-end">
                <CurrencyInput
                    size="15"
                    value={value}
                    id={name}
                    name={name}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={handleInputChange}
                    prefix="$"
                    style={{ paddingRight: '4px', textAlign: 'right' }}
                />
            </div>
        </div>
    );
};

export const BuyBoxEditablePercentItem = ({ label, name, value, onChange }) => {
    const handleChange = (ev) => {
        onChange(name, Number(ev.target.value) * 0.01);
    };

    // converting to percent and then max 1 decimal place
    const v = Math.trunc(value * 100 * 10) / 10;
    return (
        <div className="row mt-1">
            <div className="col-7">{label}</div>
            <div className="col-5 text-end">
                <span className="inputHolder">
                    <input
                        max="100"
                        min="0"
                        value={v.toString()}
                        type="number"
                        onChange={handleChange}
                        style={{ textAlign: 'left' }}
                    />
                </span>
            </div>
        </div>
    );
};

export const BuyBoxEditableItem = ({ label, value, onChange }) => (
    <div className="row align-items-center mt-1">
        <div className="col-7">{label}</div>
        <div className="col-5 text-end">
            <input
                max="1000"
                min="0"
                value={value}
                type="number"
                onChange={onChange}
                style={{ textAlign: 'right' }}
            />
        </div>
    </div>
);
