import { colors } from '../constants';

const START_DATE = 'January 1, 2025 00:0:00';
const MATERIAL_AND_MANUFACTURING_COSTS = -500000;
const FOUNDATION_AND_PREP_COSTS = -200000;
const STITCH_AND_FINISH_COSTS = -300000;

const BUYBOX_DEFAULT_VALUES = {
    sellSideCommission: 0.045,
    targetIRR: 0.31,
    timeline: {
        purchaseToClosingTimeline: 21,
        permitToSubmission: 20,
        submissionToAcceptance: 60,
        horizontalDays: 42,
        verticalDays: 40,
        listToCoE: 32,
    },
};

export function buyBoxInitializeValues(property) {
    const buyBoxValues = { ...BUYBOX_DEFAULT_VALUES };
    buyBoxValues.acquisitionPrice = Number(
        Math.round(property?.PropertyAVM.estimated_value) || 0
    );
    buyBoxValues.salePrice =
    Math.round(property?.PropertyAVM.future_sale_price) || 0;

    const genericData = property.PropertyScoutFields?.generic_data || {};
    genericData.buyBox = buyBoxValues;
    property.PropertyScoutFields.generic_data = { ...genericData };
}

export function buyBoxGetTimelineDates(timelineOffsets) {
    const dates = [new Date(START_DATE)];

    const workDate = new Date(START_DATE);
    workDate.setDate(
        workDate.getDate() + timelineOffsets.purchaseToClosingTimeline
    );
    dates.push(workDate);

    const timelineDate = new Date(START_DATE);
    timelineDate.setDate(
        timelineDate.getDate() + timelineOffsets.permitToSubmission
    );
    dates.push(new Date(timelineDate));

    timelineDate.setDate(
        timelineDate.getDate() + timelineOffsets.submissionToAcceptance
    );
    dates.push(new Date(timelineDate));

    timelineDate.setDate(timelineDate.getDate() + timelineOffsets.horizontalDays);
    dates.push(new Date(timelineDate));

    timelineDate.setDate(timelineDate.getDate() + timelineOffsets.verticalDays);
    dates.push(new Date(timelineDate));

    timelineDate.setDate(timelineDate.getDate() + timelineOffsets.listToCoE);
    dates.push(new Date(timelineDate));

    return dates;
}

export function buyBoxGetCashFlowValues(costParameters) {
    const {
        acquisitionPrice,
        additionalExemptionBasedCosts,
        sellSideCommission,
        salePrice,
    } = costParameters;
    const cashflowValues = [
        0.03 * acquisitionPrice * -1,
        0.97 * acquisitionPrice * -1,
        0,
        MATERIAL_AND_MANUFACTURING_COSTS,
        FOUNDATION_AND_PREP_COSTS,
        STITCH_AND_FINISH_COSTS,
        (salePrice - additionalExemptionBasedCosts) * (1 - sellSideCommission),
    ];
    return cashflowValues;
}

export function calculateAdditionalCosts(validationFields) {
    if (!validationFields) {
        return 0;
    }
    const costs = Object.keys(validationFields).reduce(
        (acc, key) => acc + (Number(validationFields[key].costEstimate) || 0),
        0
    );
    return costs;
}

export function computeXIRR(cashflows, dates, guess = 0.1) {
    const MAX_ITERATIONS = 1000; // Maximum iterations for the approximation
    const TOLERANCE = 1e-6; // Convergence tolerance

    console.log('Computed xIRR cashflows', cashflows);
    console.log('--- dates', dates);
    // Helper function to calculate the XNPV
    function xnpv(rate) {
        return cashflows.reduce((sum, cf, i) => {
            const diffInDays = (dates[i] - dates[0]) / (1000 * 60 * 60 * 24); // Difference in days
            return sum + cf / Math.pow(1 + rate, diffInDays / 365);
        }, 0);
    }
    // Helper function to calculate the derivative of XNPV
    function dxnpv(rate) {
        return cashflows.reduce((sum, cf, i) => {
            const diffInDays = (dates[i] - dates[0]) / (1000 * 60 * 60 * 24); // Difference in days
            const denominator = Math.pow(1 + rate, diffInDays / 365 + 1);
            return sum - ((diffInDays / 365) * cf) / denominator;
        }, 0);
    }
    // Initial guess for Newton-Raphson method
    let rate = guess;
    for (let i = 0; i < MAX_ITERATIONS; i++) {
        const npv = xnpv(rate);
        const dnpv = dxnpv(rate);
        // Update the rate using Newton-Raphson method
        const newRate = rate - npv / dnpv;
        // Check for convergence
        if (Math.abs(newRate - rate) < TOLERANCE) {
            return newRate;
        }
        rate = newRate;
    }
    throw new Error('XIRR calculation did not converge');
}

export function calculateXIRRInfoFromProperty(propertyScoutFields) {
    const {
        generic_data: { buyBox },
        validation_fields,
    } = propertyScoutFields;
    const cashFlows = buyBoxGetCashFlowValues({
        acquisitionPrice: buyBox.acquisitionPrice,
        additionalExemptionBasedCosts: calculateAdditionalCosts(validation_fields),
        sellSideCommission: buyBox.sellSideCommission,
        salePrice: buyBox.salePrice,
    });
    const timeline = buyBoxGetTimelineDates(buyBox.timeline);
    try {
        const targetIRR = buyBox.targetIRR;
        const xirr = computeXIRR(cashFlows, timeline);
        const diff = (xirr - targetIRR) * 100;
        if (xirr > targetIRR) {
            return {
                value: `${(xirr * 100).toFixed(2)} %`,
                description: 'Buy',
                style: { background: colors.aroGreen, color: colors.white },
                difference: `${diff.toFixed(1)} %`,
            };
        } else if (xirr < targetIRR) {
            return {
                value: `${(xirr * 100).toFixed(2)} %`,
                description: 'No Buy',
                style: { background: colors.aroRed, color: colors.white },
                difference: `${diff.toFixed(1)} %`,
            };
        }
        return {
            value: `${(xirr * 100).toFixed(2)} %`,
            description: 'No Decision',
            style: { background: colors.charcoal, color: colors.white },
            difference: '0 ',
        };
    } catch {
        return {
            value: 'Error - values don\'t converge',
            description: 'No Decision',
            style: {},
            difference: 'n/a',
        };
    }
}
