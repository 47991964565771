import React from 'react';

export const ScoutTab = {
    Dashboard: 'dashboard',
    Comparables: 'comparables',
    Viability: 'viability',
    BuyBox: 'buyBox',
};

function ScoutTabNavigator({ selected, onSelect }) {
    const tabStyling = (tab) =>
        selected === tab ? 'text-decoration-underline' : null;

    return (
        <ul className="nav">
            <li className="nav-item">
                <a
                    className={`nav-link ps-0 pe-3 pe-lg-5 pb-0 fs-6 ${tabStyling(
                        ScoutTab.Dashboard
                    )}`}
                    onClick={() => onSelect(ScoutTab.Dashboard)}
                >
          Dashboard
                </a>
            </li>
            <li className="nav-item">
                <a
                    className={`nav-link ps-0 pe-3 pe-lg-5 pb-0 fs-6 ${tabStyling(
                        ScoutTab.Comparables
                    )}`}
                    onClick={() => onSelect(ScoutTab.Comparables)}
                >
          Comparables
                </a>
            </li>
            <li className="nav-item">
                <a
                    className={`nav-link ps-0 pe-3 pe-lg-5 pb-0 fs-6
                                        ${tabStyling(ScoutTab.Viability)}`}
                    onClick={() => onSelect(ScoutTab.Viability)}
                >
          Viability
                </a>
            </li>
            <li className="nav-item">
                <a
                    className={`nav-link ps-0 pe-5 pb-0 fs-6 
                                        ${tabStyling(ScoutTab.BuyBox)}`}
                    onClick={() => onSelect(ScoutTab.BuyBox)}
                >
          Buy Box
                </a>
            </li>
        </ul>
    );
}

export default ScoutTabNavigator;
